import React, { useState, useRef } from 'react';
import TimeSlider from 'react-input-slider';
import styles from './Audio.module.scss';
import classNames from 'classnames/bind';
import { BsPauseFill, BsSkipBackwardFill, BsSkipEndFill, BsSkipForwardFill } from 'react-icons/bs';
import images from '~/assets/images';

const cx = classNames.bind(styles);

const Audio = ({ audioUrl }) => {
   const audioRef = useRef();
   const [audioIndex, setAudioIndex] = useState(0);
   const [currentTime, setCurrentTime] = useState(0);
   const [duration, setDuration] = useState(0);
   const [isPlay, setPlay] = useState(false);

   const audios = [
      {
         title: 'Cap Doi Dep Nhat',
         artist: 'Bach Tieu Bach',
         src: audioUrl,
      },
   ];

   const handleLoadedData = () => {
      setDuration(audioRef.current.duration);
      if (isPlay) audioRef.current.play();
   };

   const handlePausePlayClick = () => {
      if (isPlay) {
         audioRef.current.pause();
      } else {
         audioRef.current.play();
      }
      setPlay(!isPlay);
   };

   const handleTimeSliderChange = ({ x }) => {
      audioRef.current.currentTime = x;
      setCurrentTime(x);

      if (!isPlay) {
         setPlay(true);
         audioRef.current.play();
      }
   };

   return (
      <div className={cx('wrapper')}>
         <div className={cx('header')}>
            <div className={cx('content')}>
               {audios && audios.length > 0 && (
                  <>
                     <p className={cx('title')}>{audios[audioIndex].title}</p>
                     <p className={cx('singer')}>{audios[audioIndex].artist}</p>
                     <div className={cx('slider')}>
                        <TimeSlider
                           axis="x"
                           xmax={duration}
                           x={currentTime}
                           onChange={handleTimeSliderChange}
                           styles={{
                              track: {
                                 backgroundColor: '#FF8A8A',
                                 height: '2px',
                              },
                              active: {
                                 backgroundColor: '#FF5C5C',
                                 height: '2px',
                              },
                              thumb: {
                                 marginTop: '-3px',
                                 width: '8px',
                                 height: '8px',
                                 backgroundColor: '#FF5C5C',
                                 borderRadius: 0,
                              },
                           }}
                        />
                     </div>
                  </>
               )}
            </div>
         </div>

         <div className={cx('body')}>
            <div className={cx('art-body')}>
               <img className={isPlay ? cx('art', 'rotate') : cx('art')} src={images.but1} alt="" />
            </div>

            <div className={cx('control-button-group')}>
               <div className={cx('prev-next-btn')} onClick={() => setAudioIndex((audioIndex - 1) % audios.length)}>
                  <BsSkipBackwardFill />
               </div>
               <div className={cx('pp-btn')} onClick={handlePausePlayClick}>
                  {isPlay ? <BsPauseFill /> : <BsSkipEndFill />}
               </div>
               <div className={cx('prev-next-btn')} onClick={() => setAudioIndex((audioIndex + 1) % audios.length)}>
                  <BsSkipForwardFill />
               </div>
            </div>
         </div>

         <audio
            ref={audioRef}
            src={audios && audios.length > 0 ? audios[audioIndex].src : ''}
            onLoadedData={handleLoadedData}
            onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)}
            onEnded={() => setPlay(false)}
         />
      </div>
   );
};

export default Audio;
