import classNames from 'classnames/bind';
import styles from './LoadingProgress.module.scss';

const cx = classNames.bind(styles);

function LoadingProgress() {
   return (
      <div className={cx('lds-heart')}>
         <div></div>
      </div>
   );
}

export default LoadingProgress;
