import classNames from 'classnames/bind';
import styles from './SettingGallery.module.scss';
import { useEffect, useState } from 'react';
import Button from '~/component/Button';
import axios from 'axios';
import config from '~/config';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
const cx = classNames.bind(styles);

function SettingGallery() {
   const [user, setUser] = useState();
   const [connectDriver, setConnectDriver] = useState(false);
   const [folderName, setFolderName] = useState('');
   const [oldFolderName, setOldFolderName] = useState('');
   const [needUpdate, setNeedUpdate] = useState(false);

   const navigate = useNavigate();

   useEffect(() => {
      const theUser = JSON.parse(localStorage.getItem('user'));
      if (theUser && theUser._id) {
         axios
            .get(`${config.api.url}/auth/connect-driver-status`, {
               headers: {
                  Authorization: `Bearer ${theUser?.token}`,
               },
            })
            .then((res) => {
               setUser(theUser);
               setConnectDriver(res.data.connected);
               setFolderName(res.data.driverFolderName);
               setOldFolderName(res.data.driverFolderName);
               setNeedUpdate(res.data.needUpdate);
            });
      } else {
         navigate(config.routes.login);
      }
   }, [navigate]);

   const handleFolderName = (e) => {
      if (e.target.value !== oldFolderName) {
         setFolderName(e.target.value);
         setNeedUpdate(true);
      } else {
         setFolderName(e.target.value);
         setNeedUpdate(false);
      }
   };

   const handleConnectDriver = () => {
      if (!folderName) {
         swal('Error', 'Folder name is required', 'error');
         return;
      }

      axios
         .post(
            `${config.api.url}/auth/connect-driver`,
            {
               folderName,
            },
            {
               headers: {
                  Authorization: `Bearer ${user?.token}`,
               },
            },
         )
         .then((res) => {
            console.log(res);
         })
         .catch((err) => {
            console.log(err);
         });
   };

   const handleSync = () => {
      if (!connectDriver || needUpdate) {
         return;
      }

      axios
         .post(
            `${config.api.url}/gallery/sync-driver`,
            {},
            {
               headers: {
                  Authorization: `Bearer ${user?.token}`,
               },
            },
         )
         .then((res) => {
            console.log(res);
         })
         .catch((err) => {
            console.log(err);
         });
   };

   return (
      <div className={cx('wrapper')}>
         <div className={cx('header')}>
            <p>{connectDriver ? 'Connected driver' : 'Not Connected'}</p>
            <span className={cx('span-text')}>Bạn có thể kết nối đến driver bằng cách bấm vào nút bên dưới.</span>
            <span className={cx('span-text')}>Cấp quyền để có thể load ảnh lên nha!</span>
         </div>
         <div className={cx('body')}>
            <div className={cx('folder-name')}>
               <div className={cx('folder-name-content')}>
                  <p className={cx('title')}>Tên thư mục</p>
                  <input className={cx('input')} value={folderName} onChange={handleFolderName} type="text" />
               </div>
               <span className={cx('span-text')}>
                  App sẽ load ảnh trong thư mục này. Nếu thư mục chưa tồn tại, sẽ tự động tạo mới
               </span>
            </div>
            {connectDriver && !needUpdate && (
               <Button outline onClick={handleSync}>
                  Sync
               </Button>
            )}
            {(!connectDriver || needUpdate) && (
               <Button primary onClick={handleConnectDriver}>
                  {connectDriver ? 'Reconnect driver' : 'Connect driver'}
               </Button>
            )}
         </div>
         <Button as={Link} to={config.routes.gallery} className={cx('btn')}>
            Cancel
         </Button>
      </div>
   );
}

export default SettingGallery;
