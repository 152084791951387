import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import { useEffect, useState } from 'react';
import Image from '~/layouts/components/Image';
import images from '~/assets/images';
import Button from '~/component/Button';
import axios from 'axios';
import config from '~/config';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { AiOutlineMessage } from 'react-icons/ai';
import { BsImage, BsList, BsMoonStars } from 'react-icons/bs';
import AddConversation from '../Chat/AddConversation';
const cx = classNames.bind(styles);

function Home() {
   const [user, setUser] = useState();
   const [content, setContent] = useState();
   const navigate = useNavigate();

   useEffect(() => {
      const theUser = JSON.parse(localStorage.getItem('user'));
      if (theUser && theUser._id) {
         setUser(theUser);
      } else {
         navigate(config.routes.login);
      }
   }, [navigate]);

   const handleChangeContent = (e) => {
      setContent(e.target.value);
   };

   const handleSubmit = () => {
      axios
         .post(
            `${config.api.url}/treaty/create`,
            {
               content: content,
            },
            {
               headers: {
                  Authorization: `Bearer ${user?.token}`,
               },
            },
         )
         .then((res) => {
            swal('Success', 'Ta đã nhận điều ước của con!', 'success');
         })
         .catch((err) => {
            swal('Error', err.response.data.message, 'error');
         });
   };

   return (
      <div className={cx('wrapper')}>
         {user && user.hasCouple ? (
            <>
               <Link to={config.routes.memory} className={cx('header')}>
                  Memory
                  <BsMoonStars className={cx('icon-message-star')} />
               </Link>
               <Link to={config.routes.gallery} className={cx('header')}>
                  Gallery
                  <BsImage className={cx('icon-message-star')} />
               </Link>
               <Link to={config.routes.chat} className={cx('header')}>
                  Chat with Bụt
                  <AiOutlineMessage className={cx('icon-message')} />
               </Link>
               <Link to={config.routes.treaty} className={cx('header')}>
                  Điều ước's
                  <BsList className={cx('icon-message-star')} />
               </Link>

               <div className={cx('main')}>
                  <Image className={cx('ong-but')} src={images.but1} />
                  <p className={cx('text')}>Hello {user?.firstName},</p>
                  <p className={cx('text')}>
                     Ta là Bụt đây! Ngạc nhiên trước sự đáng iu của con nên ta sẽ ban cho con 1 điều ước!!
                  </p>
                  <textarea
                     className={cx('message')}
                     placeholder="Viết điều ước của con vào đây nha"
                     value={content}
                     onChange={handleChangeContent}
                  />
                  <Button onClick={handleSubmit} primary className={cx('btn')}>
                     Gửi điều ước
                  </Button>
               </div>
            </>
         ) : (
            <AddConversation user={user} />
         )}
      </div>
   );
}

export default Home;
