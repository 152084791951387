//define status of socket
export const socketStatus = {
   connection: 'connection',
   joinRoom: 'joinRoom',
   leaveRoom: 'leaveRoom',
   sendMessage: 'sendMessage',
   chatWithAI: 'chatWithAI',
   receiveMessage: 'receiveMessage',
   receiveNoti: 'receiveNoti',
   disconnecting: 'disconnecting',
   disconnect: 'disconnect',
};

export const socketMessage = {
   userLeft: 'userLeft',
   userJoined: 'userJoined',
   userTyping: 'userTyping',
   userStopTyping: 'userStopTyping',
};
