import { Canvas } from 'react-three-fiber';
import Cube from './components/Cube';
import styles from './Memory.module.scss';
import classNames from 'classnames/bind';
import Audio from './components/Audio';
import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '~/config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingProgress from '~/component/LoadingProgress/LoadingProgress';

const cx = classNames.bind(styles);

function Memory() {
   const [backgroundImage, setBackgroundImage] = useState('');
   const [memory, setMemory] = useState({});
   const [curIndexCaption, setCurIndexCaption] = useState(0);
   const navigate = useNavigate();
   const memoryId = useParams().memoryId;
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      setIsLoading(true);
      if (memoryId) {
         axios.get(`${config.api.url}/memory/${memoryId}`).then((res) => {
            const { memory } = res.data;
            while (memory && memory.images.length < 6) {
               memory.images.push(memory.images[0]);
            }
            setMemory(memory);
            setBackgroundImage(`url(${memory.background})`);
         });
      } else {
         const theUser = JSON.parse(localStorage.getItem('user'));
         if (!theUser || !theUser._id) {
            navigate(config.routes.login);
         }

         axios
            .get(`${config.api.url}/memory/get`, {
               headers: {
                  Authorization: `Bearer ${theUser?.token}`,
               },
            })
            .then((res) => {
               const { memory } = res.data;
               while (memory && memory.images.length < 6) {
                  memory.images.push(memory.images[0]);
               }
               setMemory(memory);
               setBackgroundImage(`url(${memory.background})`);
            });
      }
      setIsLoading(false);
   }, [memoryId, navigate]);

   useEffect(() => {
      const interval = setInterval(() => {
         if (memory && memory.content) {
            setCurIndexCaption((curIndexCaption + 1) % memory.content.length);
         }
      }, 120);

      return () => clearInterval(interval);
   }, [curIndexCaption, memory]);

   return (
      <div className={cx('wrapper')}>
         {isLoading || !memory || (memory && !memory._id) ? (
            <div className={cx('page-loading')}>
               <LoadingProgress />
            </div>
         ) : (
            <>
               <div className={cx('background')} style={{ backgroundImage: backgroundImage }}></div>
               <img className={cx('background-img')} src={memory.background} alt="background" />
               <div className={cx('route-btn')}>
                  <div className={cx('btn-home')}>
                     <Link to={config.routes.home}>Home</Link>
                  </div>
                  <div className={cx('btn-edit')}>
                     <Link to={config.routes.gallery}>Edit</Link>
                  </div>
               </div>

               <div className={cx('box')}>
                  <Cube images={memory.images} />
               </div>
               <div className={cx('caption')}>
                  <p>{memory.content.slice(0, curIndexCaption)}...</p>
               </div>
               <div className={cx('audio')}>
                  <Audio audioUrl={memory.music} />
               </div>
            </>
         )}
      </div>
   );
}

export default Memory;
