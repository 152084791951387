import styles from './HeaderChatArea.module.scss';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import { BsFillCircleFill } from 'react-icons/bs';
import { IoChevronBackOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import config from '~/config';

const cx = classNames.bind(styles);

function HeaderChatArea({ activeStatus, isChatWithAI, onChangeChatWithAI }) {
   const onErrorImage = (image) => {
      image.onError = '';
      image.target.src = images.noImage;
      return true;
   };

   return (
      <div className={cx('wrapper')}>
         <div className={cx('content')}>
            <Link to={config.routes.home}>
               <IoChevronBackOutline className={cx('back-btn')} />
            </Link>
            <div className={cx('avatar')}>
               <img src={images.but1} alt="avatar" onError={onErrorImage} />
            </div>
            <div className={cx('infoUser')}>
               <div className={cx('name')}>Ong But</div>
               <div className={cx('active')}>
                  <BsFillCircleFill className={cx('status', activeStatus ? 'online' : 'offline')} />
                  {activeStatus ? 'Đang lắng nghe bạn' : 'Ngủ mất tiêu rùi'}
               </div>
            </div>
            <div className={cx('chatWithAI')}>
               <input className={cx('select')} type="checkbox" checked={isChatWithAI} onChange={onChangeChatWithAI} />
               <span>AI</span>
            </div>
         </div>
      </div>
   );
}

export default HeaderChatArea;
