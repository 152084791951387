import classNames from 'classnames/bind';
import styles from './Treaty.module.scss';
import { useEffect, useState } from 'react';
import Image from '~/layouts/components/Image';
import images from '~/assets/images';
import axios from 'axios';
import config from '~/config';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { BsMoonStars } from 'react-icons/bs';
import TreatyItem from './components/TreatyItem';

const cx = classNames.bind(styles);

function Treaty() {
   const [listTreaty, setListTreaty] = useState([]);
   const [couple, setCouple] = useState({});

   const navigate = useNavigate();

   useEffect(() => {
      const theUser = JSON.parse(localStorage.getItem('user'));
      if (theUser && theUser._id) {
         axios
            .get(`${config.api.url}/treaty/getTreaties`, {
               headers: {
                  Authorization: `Bearer ${theUser.token}`,
               },
            })
            .then((res) => {
               setListTreaty(res.data.treaties);
               setCouple(res.data.couple);
            })
            .catch((err) => {
               swal('Error', err.response.data.message, 'error');
            });
      } else {
         navigate(config.routes.login);
      }
   }, [navigate]);

   return (
      <div className={cx('wrapper')}>
         <Link to={config.routes.home} className={cx('header')}>
            Home
            <BsMoonStars className={cx('icon-message-star')} />
         </Link>
         <div className={cx('main')}>
            <Image className={cx('ong-but')} src={images.but1} />
            <p className={cx('text')}>
               Đây là điều ước của {couple.firstUser} và {couple.secondUser}
            </p>
            <p className={cx('text')}>Cùng nhau cố gắng thực hiện nhaaa!!</p>
            {listTreaty.map((item, index) => {
               return (
                  <div key={index} className={cx('list-treaty')}>
                     <TreatyItem content={item.content} />
                  </div>
               );
            })}
         </div>
      </div>
   );
}

export default Treaty;
