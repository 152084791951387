import classNames from 'classnames/bind';
import styles from './CreateMemory.module.scss';
import { useEffect, useState } from 'react';
import Image from '~/layouts/components/Image';
import Button from '~/component/Button';
import axios from 'axios';
import config from '~/config';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
const cx = classNames.bind(styles);

function CreateMemory() {
   const [user, setUser] = useState();
   const [content, setContent] = useState();
   const [images, setImages] = useState();
   const [background, setBackground] = useState();
   const [music, setMusic] = useState();

   const navigate = useNavigate();

   useEffect(() => {
      const theUser = JSON.parse(localStorage.getItem('user'));
      if (theUser && theUser._id) {
         setUser(theUser);
      } else {
         navigate(config.routes.login);
      }
   }, [navigate]);

   const handleChangeBackground = (e) => {
      setBackground(e.target.files[0]);
   };

   const handleChangeImages = (e) => {
      setImages(e.target.files);
   };

   const handleChangeContent = (e) => {
      setContent(e.target.value);
   };

   const handleChangeMusic = (e) => {
      setMusic(e.target.files[0]);
   };

   const handleSubmit = () => {
      if (!background || !images || !content || !music) return swal('Error', 'Please choose background', 'error');

      const formData = new FormData();
      formData.append('background', background);
      for (let i = 0; i < images.length; i++) {
         formData.append('images', images[i]);
      }
      formData.append('content', content);
      formData.append('music', music);

      axios
         .post(`${config.api.url}/memory/create`, formData, {
            headers: {
               Authorization: `Bearer ${user?.token}`,
            },
         })
         .then((res) => {
            swal('Success', 'Send!', 'success');
         })
         .catch((err) => {
            swal('Error', err.response.data.message, 'error');
         });
   };

   return (
      <div className={cx('wrapper')}>
         <div className={cx('choose-background')}>
            <p className={cx('title')}>Choose background</p>
            <input className={cx('select-background')} onChange={handleChangeBackground} type="file" accept="image/*" />
            {background && <Image src={URL.createObjectURL(background)} className={cx('background')} />}
         </div>
         <div className={cx('choose-images')}>
            <p className={cx('title')}>Choose images</p>
            <input
               className={cx('select-images')}
               onChange={handleChangeImages}
               type="file"
               multiple="multiple"
               accept="image/*"
            />
            <div className={cx('images')}>
               {images &&
                  images.length > 0 &&
                  Array.from(images).map((image, index) => {
                     return <Image src={URL.createObjectURL(image)} className={cx('image')} key={index} />;
                  })}
            </div>
         </div>
         <textarea className={cx('content')} onChange={handleChangeContent} type="text" />
         <div className={cx('choose-music')}>
            <p className={cx('title')}>Choose music</p>
            <input className={cx('select-music')} onChange={handleChangeMusic} type="file" accept="audio/*" />
         </div>
         <Button onClick={handleSubmit} primary className={cx('btn')}>
            Save
         </Button>
         <Button as={Link} to={config.routes.memory} className={cx('btn')}>
            Cancel
         </Button>
      </div>
   );
}

export default CreateMemory;
