const routes = {
   login: '/login',
   logout: '/logout',
   home: '/',
   chat: '/chat',
   treaty: '/treaty',
   memory: '/memory',
   gallery: '/gallery',
   policy: '/policy',
};

export default routes;
