import config from '~/config';

// Layouts
import { ContentOnly } from '~/layouts';

// Pages
import Login from '~/pages/Login';
import Home from '~/pages/Home';
import Chat from '~/pages/Chat';
import Treaty from '~/pages/Treaty';
import Memory from '~/pages/Memory';
import CreateMemory from '~/pages/Memory/components/CreateMemory';
import Gallery from '~/pages/Gallery';
import SettingGallery from '~/pages/Gallery/Setting';
import Policy from '~/pages/Policy';

// Public routes
const publicRoutes = [
   { path: config.routes.login, component: Login, layout: ContentOnly },
   { path: config.routes.home, component: Home },
   { path: config.routes.chat, component: Chat },
   { path: config.routes.treaty, component: Treaty },
   { path: config.routes.memory, component: Memory },
   { path: `${config.routes.memory}/:memoryId`, component: Memory },
   { path: `${config.routes.memory}/create`, component: CreateMemory },
   { path: config.routes.gallery, component: Gallery },
   { path: `${config.routes.gallery}/setting`, component: SettingGallery },
   { path: `${config.routes.policy}`, component: Policy, layout: ContentOnly },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
