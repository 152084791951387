import React, { useEffect, useState } from 'react';
import './style.css';

const Cube = ({ images }) => {
   const [shapeClass, setShapeClass] = useState('cube cube-backfaces');
   const [count, setCount] = useState(0);

   useEffect(() => {
      const timer = setInterval(() => {
         setCount((prevCount) => {
            if (prevCount >= 8) {
               autoLoad();
               return 0;
            }
            return prevCount + 1;
         });
      }, 1000);

      return () => clearInterval(timer);
   }, []);

   const hasClassName = (element, className) => {
      const regExp = new RegExp('(?:^|\\s+)' + className + '(?:\\s+|$)');
      return regExp.test(element.className);
   };

   const addClassName = (element, className) => {
      if (!hasClassName(element, className)) element.className = [element.className, className].join(' ');
   };

   const removeClassName = (element, className) => {
      if (hasClassName(element, className)) {
         const regExp = new RegExp('(?:^|\\s+)' + className + '(?:\\s+|$)', 'g');
         const curClasses = element.className;
         element.className = curClasses.replace(regExp, ' ').trim();
      }
   };

   const toggleShape = () => {
      const shape = document.getElementById('cube-shape');
      if (hasClassName(shape, 'cube') || hasClassName(shape, 'cube-autoLoad')) {
         if (hasClassName(shape, 'cube')) {
            removeClassName(shape, 'cube');
         }
         if (hasClassName(shape, 'cube-autoLoad')) {
            removeClassName(shape, 'cube-autoLoad');
         }
         addClassName(shape, 'cube-ring');
      } else {
         removeClassName(shape, 'cube-ring');
         addClassName(shape, 'cube');
      }

      const stage = document.getElementById('cube-stage');
      if (hasClassName(shape, 'cube-ring')) stage.style.webkitTransform = 'translateZ(-200px)';
      else stage.style.webkitTransform = '';
   };

   const autoLoad = () => {
      const shapeTest = document.getElementById('cube-shape');
      if (hasClassName(shapeTest, 'cube')) {
         removeClassName(shapeTest, 'cube');
         addClassName(shapeTest, 'cube-autoLoad');
      } else {
         toggleShape();
      }
   };

   return (
      <div id="cube-container">
         <div id="cube-stage">
            <div id="cube-shape" className={shapeClass}>
               <div className="cube-plane cube-one" style={{ backgroundImage: `url(${images[0]})` }}></div>
               <div className="cube-plane cube-two" style={{ backgroundImage: `url(${images[1]})` }}></div>
               <div className="cube-plane cube-three" style={{ backgroundImage: `url(${images[2]})` }}></div>
               <div className="cube-plane cube-four" style={{ backgroundImage: `url(${images[3]})` }}></div>
               <div className="cube-plane cube-five" style={{ backgroundImage: `url(${images[4]})` }}></div>
               <div className="cube-plane cube-six" style={{ backgroundImage: `url(${images[5]})` }}></div>
               <div className="cube-plane cube-seven" style={{ backgroundImage: `url(${images[6]})` }}></div>
               <div className="cube-plane cube-eight" style={{ backgroundImage: `url(${images[7]})` }}></div>
               <div className="cube-plane cube-nine" style={{ backgroundImage: `url(${images[8]})` }}></div>
               <div className="cube-plane cube-ten" style={{ backgroundImage: `url(${images[9]})` }}></div>
               <div className="cube-plane cube-eleven" style={{ backgroundImage: `url(${images[10]})` }}></div>
               <div className="cube-plane cube-twelve" style={{ backgroundImage: `url(${images[11]})` }}></div>
            </div>
         </div>
      </div>
   );
};

export default Cube;
