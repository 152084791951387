import classNames from 'classnames/bind';
import styles from './Gallery.module.scss';
import { useEffect, useState } from 'react';
import Image from '~/layouts/components/Image';
import axios from 'axios';
import config from '~/config';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { GrAddCircle } from 'react-icons/gr';
import { BiImage, BiCubeAlt, BiExit, BiCheck } from 'react-icons/bi';
import Resizer from 'react-image-file-resizer';

const cx = classNames.bind(styles);

function Gallery() {
   const [user, setUser] = useState();
   const [gallery, setGallery] = useState([]);
   const [selectedImages, setSelectedImages] = useState([]);
   const [isSelectBackground, setIsSelectBackground] = useState(false);
   const [isSelectCube, setIsSelectCube] = useState(false);
   const [isUpload, setIsUpload] = useState(false);

   const navigate = useNavigate();

   useEffect(() => {
      const theUser = JSON.parse(localStorage.getItem('user'));
      if (theUser && theUser._id) {
         axios
            .get(`${config.api.url}/gallery/get`, {
               headers: {
                  Authorization: `Bearer ${theUser?.token}`,
               },
            })
            .then((res) => {
               setUser(theUser);
               setGallery(res.data.imagesUrl);
            });
      } else {
         navigate(config.routes.login);
      }
   }, [navigate]);

   const handleCancelSelectMode = () => {
      setIsSelectBackground(false);
      setIsSelectCube(false);
   };

   const handleImageSelect = (image) => {
      const index = selectedImages.indexOf(image);
      if (index === -1) {
         setSelectedImages([...selectedImages, image]);
      } else {
         setSelectedImages([...selectedImages.slice(0, index), ...selectedImages.slice(index + 1)]);
      }
   };

   const resizeFile = (file) =>
      new Promise((resolve) => {
         Resizer.imageFileResizer(
            file,
            1024,
            1024,
            'JPEG',
            100,
            0,
            (uri) => {
               resolve(uri);
            },
            'base64',
         );
      });

   const handleSendImages = () => {
      console.log(selectedImages);
      if (!selectedImages.length > 0) return;

      if (isSelectCube && selectedImages.length < 12) return;

      let endpoint = '/change-background';
      let body = { background: selectedImages[0] };

      if (isSelectCube) {
         endpoint = '/change-cube';
         body = { images: selectedImages.slice(0, 12) };
      }
      axios
         .post(`${config.api.url}/memory${endpoint}`, body, {
            headers: {
               Authorization: `Bearer ${user?.token}`,
            },
         })
         .then((res) => {
            handleCancelSelectMode();
            navigate(config.routes.memory);
         })
         .catch((err) => {
            swal('Error', err.response.data.message, 'error');
         });
   };

   const handleChangeImages = (e) => {
      if (e.target.files.length > 0) handleSubmit(e.target.files);
   };

   const handleSubmit = async (images) => {
      if (!images) return swal('Error', 'Please choose at least picture', 'error');

      setIsUpload(true);
      const formData = new FormData();
      const imagesList = [];
      for (let i = 0; i < images.length; i++) {
         const tempImg = await resizeFile(images[i]);
         console.log(tempImg);
         imagesList.push(tempImg);
         formData.append('images', tempImg);
         formData.append('name', images[i].name);
      }

      axios
         .post(
            `${config.api.url}/gallery/add`,
            {
               images: imagesList,
            },
            {
               headers: {
                  Authorization: `Bearer ${user?.token}`,
               },
            },
         )
         .then((res) => {
            setGallery(res.data.imagesUrl);
            setIsUpload(false);
         })
         .catch((err) => {
            swal('Error', err.response.data.message, 'error');
            setIsUpload(false);
         });
   };

   return (
      <div className={cx('wrapper')}>
         <div className={cx('header')}>
            {isSelectCube ? (
               <p> Chọn 12 ảnh để tạo box nha! </p>
            ) : (
               <> {isSelectBackground && <p> Chọn 1 ảnh thật xinh để làm nền nhớ!! </p>}</>
            )}
            <div>
               <p>Ảnh xinh của cả 2 ở đâyy!!</p>
            </div>
         </div>
         <div className={cx('gallery')}>
            {gallery &&
               gallery.length > 0 &&
               gallery.map((image, index) => {
                  return (
                     <div className={cx('image-wrapper')} key={index}>
                        {(isSelectBackground || isSelectCube) && (
                           <label>
                              <input
                                 className={cx('select')}
                                 type="checkbox"
                                 checked={selectedImages.includes(image)}
                                 onChange={() => handleImageSelect(image)}
                              />
                           </label>
                        )}
                        <Image src={image} className={cx('image')} />
                     </div>
                  );
               })}

            {isUpload && (
               <div className={cx('image-wrapper')}>
                  <div className={cx('image')}> Đợi chút ảnh đang được tải lên... </div>
               </div>
            )}
         </div>

         {isSelectBackground || isSelectCube ? (
            <div className={cx('nav-bar')}>
               <div className={cx('nav-item')} onClick={handleSendImages}>
                  <BiCheck />
               </div>
               <div className={cx('nav-item')} onClick={handleCancelSelectMode}>
                  <BiExit />
               </div>
            </div>
         ) : (
            <div className={cx('nav-bar')}>
               <div className={cx('nav-item')} onClick={() => document.querySelector('.select-images').click()}>
                  <GrAddCircle />
               </div>
               <div className={cx('nav-item')} onClick={() => setIsSelectBackground(!isSelectBackground)}>
                  <BiImage />
               </div>
               <div className={cx('nav-item')} onClick={() => setIsSelectCube(!isSelectCube)}>
                  <BiCubeAlt />
               </div>
               <div className={cx('nav-item')}>
                  <Link to={config.routes.home} className={cx('nav-item')}>
                     <BiExit />
                  </Link>
               </div>

               <input
                  className={cx('select-images')}
                  onChange={handleChangeImages}
                  type="file"
                  multiple="multiple"
                  accept="image/jpeg,image/png"
                  hidden
               />
            </div>
         )}
      </div>
   );
}

export default Gallery;
