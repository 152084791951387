import classNames from 'classnames/bind';
import styles from './TreatyItem.module.scss';

const cx = classNames.bind(styles);

function TreatyItem({ content }) {
   return <div className={cx('wrapper')}>{content}</div>;
}

export default TreatyItem;
