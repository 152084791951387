import classNames from 'classnames/bind';
import styles from './AddConversation.module.scss';
import axios from 'axios';
import Button from '~/component/Button';
import { useState } from 'react';
import config from '~/config';
import Image from '~/layouts/components/Image/Image';
import images from '~/assets/images';

const cx = classNames.bind(styles);

function AddConversation({ user }) {
   const [friendEmail, setFriendEmail] = useState();
   const [message, setMessage] = useState();

   const handleChangeEmail = (e) => {
      setFriendEmail(e.target.value);
   };

   const handleSubmit = () => {
      axios
         .get(`${config.api.url}/chat/conversation?fe=${friendEmail}`, {
            headers: {
               Authorization: `Bearer ${user.token}`,
            },
         })
         .then((res) => {
            window.location.reload();
         })
         .catch((err) => {
            setMessage(err.response.data.message);
         });
   };

   return (
      <div className={cx('wrapper')}>
         <div className={cx('main')}>
            <Image className={cx('ong-but')} src={images.but1} />
            <p className={cx('text')}>Hello {user?.firstName},</p>
            <p className={cx('text')}>Nhập email nửa kia của con để ta kết nối nha!!</p>
         </div>

         <div className={cx('message')}>{message}</div>
         <input className={cx('email')} placeholder="Email" value={friendEmail} onChange={handleChangeEmail} />
         <Button primary className={cx('add-btn')} onClick={handleSubmit}>
            Xác nhận
         </Button>
      </div>
   );
}

export default AddConversation;
